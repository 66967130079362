<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <div class="d-flex align-center">
        <h3 class="text-h5 mb-2 mr-3">Users</h3>
      </div>
      <v-btn
          v-if="can($permissions['USERS.UPSERT'])"
          color="primary"
          @click="openModal"
      >
        Добавить
      </v-btn>
    </div>
    <v-row>
      <v-col cols="6">
        <div class="d-flex align-center">
          <v-text-field
              class="mr-2"
              label="Username"
              outlined
              hide-details="auto"
              v-model="form.username"
          ></v-text-field>
          <v-text-field
              class="mr-2"
              label="Phone number"
              outlined
              hide-details="auto"
              v-model="form.phoneNumber"
          ></v-text-field>
          <v-checkbox
              v-model="form.isActive"
              label="Active"
          ></v-checkbox>
          <v-btn
              class="ml-3"
              color="primary"
              @click="getList"
          >
            Поиск
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th style="max-width: 50px" class="text-left">
              №
            </th>
            <th>Username</th>
            <th>FIO</th>
            <th>Phone number</th>
            <th>Blocked</th>
            <th>Deleted</th>
            <th v-if="ableToAction" class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="faq-tr"
              v-for="(item, itemIndex) in list"
              :key="itemIndex"
          >
            <td>{{ itemIndex + 1 }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.fio }}</td>
            <td>{{ item.phoneNumber }}</td>
            <td>{{ item.isBlocked }}</td>
            <td>{{ item.isDeleted }}</td>
            <td v-if="ableToAction">
              <v-row justify="center">
                <v-icon
                    v-if="can($permissions['USERS.UPSERT'])"
                    @click="getItem(item.id)"
                    class="cursor-pointer mr-2">mdi-pencil
                </v-icon>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-card>
    <div v-if="totalPages > 1" class="text-center mt-3">
      <v-pagination
          v-model="pagination.page"
          :length="totalPages"
      ></v-pagination>
    </div>
    <!--  Modals  -->
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span v-if="update" class="headline">Изменить</span>
          <span v-else class="headline">Добавить</span>
        </v-card-title>
        <v-form autocomplete="off">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="FIO"
                      outlined
                      hide-details="auto"
                      v-model="item.fio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Phone number"
                      outlined
                      hide-details="auto"
                      v-model="item.phoneNumber"
                  ></v-text-field>
                </v-col>
                <v-col v-if="!update" cols="12">
                  <v-text-field
                      :rules="requestField"
                      label="Username"
                      outlined
                      hide-details="auto"
                      v-model="item.username"
                      @blur="checkUsername"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      type="password"
                      label="Password"
                      outlined
                      hide-details="auto"
                      v-model="item.password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                      v-model="item.questionId"
                      :items="questions"
                      item-text="valueRu"
                      item-value="id"
                      label="Question"
                      outlined
                      hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Answer"
                      outlined
                      hide-details="auto"
                      v-model="item.questionAnswer"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                      v-model="item.chosenLanguage"
                      :items="$store.getters.languages"
                      item-text="value"
                      item-value="id"
                      label="Language"
                      outlined
                      hide-details="auto"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Закрыть
            </v-btn>
            <v-btn
                v-if="update"
                color="blue darken-1"
                text
                @click="upsert"
            >
              Изменить
            </v-btn>
            <v-btn
                v-else
                color="blue darken-1"
                text
                @click="saveItem"
            >
              Добавить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      questions: [],
      item: {
        id: 0,
        fio: '',
        phoneNumber: '',
        password: '',
        questionId: 0,
        questionAnswer: '',
        chosenLanguage: '',
        username: ''
      },
      dialog: false,
      update: false,
      userNameValid: true,
      form: {
        username: null,
        isActive: true,
        phoneNumber: "",
        pageNumber: 0,
        pageSize: 0
      },
      pagination: {
        page: 1,
        pageSize: 10,
        totalCount: 0
      }
    }
  },
  computed: {
    ableToAction() {
      return this.can(this.$permissions['USERS.UPSERT'])
    },
    totalPages() {
      return Math.ceil(this.pagination.totalCount / this.pagination.pageSize);
    }
  },
  methods: {
    async checkUsername() {
      if (this.item.username !== null) {
        try {
          const res = await this.$http.get('/api-authUser/checkUsername', {params: {username: this.item.username}});
          if(!res.result) {
            this.item.username = null;
            this.errorNotification('Username exists');
          }
        } catch (e) {
          this.errorNotification(e.response.data.message)
        }
      }
    },
    async getQuestions() {
      try {
        const res = await this.$http.get('/api-question/getQuestions');
        this.questions = res.result;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async getList() {
      this.showLoader();
      this.form.pageNumber = this.pagination.page;
      this.form.pageSize = this.pagination.pageSize;
      try {
        const res = await this.$http.post('/api-authUser/getUsersList', this.form);
        this.list = res.result.users;
        this.pagination.totalCount = res.result.totalCount;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async getItem(id) {
      this.update = true;
      try {
        const res = await this.$http.get(`/api-authUser/getUserData?userId=${id}`);
        this.item = res.result;
        this.dialog = true;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async saveItem() {
      try {
        const res = await this.$http.post('/api-authUser/addUser', this.item);
        this.dialog = false;
        await this.getList();
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async upsert() {
      try {
        const res = await this.$http.post('/api-authUser/updateUser', {
          id: this.item.id,
          fio: this.item.fio,
          phoneNumber: this.item.phoneNumber,
          password: this.item.password,
          questionId: this.item.questionId,
          questionAnswer: this.item.questionAnswer,
          chosenLanguage: this.chosenLanguage,
        });
        this.dialog = false;
        await this.getList();
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    openModal() {
      this.resetForm();
      this.update = false;
      this.dialog = true;
    },
    resetForm() {
      this.item = {
        id: 0,
        fio: '',
        phoneNumber: '',
        password: '',
        questionId: 0,
        questionAnswer: '',
        chosenLanguage: '',
        username: ''
      }
    }
  },
  watch: {
    'pagination.page': function (val) {
      this.getList();
    }
  },
  created() {
    this.getList();
    this.getQuestions();
  }
}
</script>

<style scoped>
.faq-tr:first-child .move-icon-up {
  display: none;
}

.faq-tr:last-child .move-icon-down {
  display: none;
}
</style>